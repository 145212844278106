import React, { Component } from "react";
import { Main } from "./components/";
import "./App.css";

class App extends Component {
  render() {
    return <Main location={this.props.location.pathname} />;
  }
}

export default App;
