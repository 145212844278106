import React, { useState } from "react";
import LocalBillerList from "../localBillerList/LocalBillerList";
import EnrollBiller from "../enrollment/EnrollBiller";
import EditBiller from "../enrollment/EditBiller";

const Main = (props) => {
  const [enrollBiller, setEnrollBiller] = useState("");
  const [editBiller, setEditBiller] = useState("");

  if (enrollBiller) {
    return <EnrollBiller enroll={setEnrollBiller} />;
  } else if (editBiller) {
    return <EditBiller initialValue={editBiller} edit={setEditBiller} />;
  } else
    return <LocalBillerList enroll={setEnrollBiller} edit={setEditBiller} />;
};

export default Main;
