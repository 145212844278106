import axios from "./axios";
import { ErrorTexts } from "../enums";
const LocalBillersService = {
  getCity: async () => {
    try {
      const response = await axios.get(`/address?addressType=CITY`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return ErrorTexts.services.message;
      }
    }
  },

  getBarangay: async (query) => {
    try {
      const response = await axios.get(
        `/address?addressType=BARANGAY&input=${query}`
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return ErrorTexts.services.message;
      }
    }
  },

  enrollBiller: async (requestObject) => {
    try {
      const response = await axios.post(`localBiller/enroll`, requestObject);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return ErrorTexts.services.message;
      }
    }
  },

  updateBiller: async (requestObject) => {
    try {
      const response = await axios.put(`/localBiller/update`, requestObject);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return ErrorTexts.services.message;
      }
    }
  },

  deleteBiller: async (billerCode) => {
    try {
      const response = await axios.delete(`/localBiller/${billerCode}/delete`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return ErrorTexts.services.message;
      }
    }
  },

  getBillers: async () => {
    try {
      const response = await axios.get(
        `/localBiller/getBillers?limit=50&page=1`
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return ErrorTexts.services.message;
      }
    }
  },
};

export default LocalBillersService;
