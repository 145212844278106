import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import Check from "@material-ui/icons/Check";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Create from "@material-ui/icons/Create";
import CloseIcon from "@material-ui/icons/NotInterested";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Icon as MdIcon } from "@mdi/react";
import { mdiTrashCanOutline } from "@mdi/js";
import SearchUtil from "../../utils/SearchUtil";
import TablePagination from "@material-ui/core/TablePagination";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LocalBillersService from "../../services/LocalBillersService";
import moment from "moment";
import InputBase from "@material-ui/core/InputBase";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import UIActions from "../../actions/UIActions";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F47721",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#F47721",
    },
    charcoal: {
      main: "#FFFFFF",
      contrastText: "#2B2D33",
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: "none",
      },
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  backgroundColor: "#FFFFFF",
});

const LocalBillerList = (props) => {
  const [filteredList, setfilteredList] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [errors, setErrors] = React.useState("");

  async function getBillers() {
    const response = await LocalBillersService.getBillers();

    if (response && response.list) {
      setfilteredList(response.list);
    } else {
      setErrors(response);
    }
  }

  const handleFilter = (value) => {
    if (value) {
      setfilteredList(SearchUtil.search(value, filteredList));
    } else {
      getBillers();
      setfilteredList(filteredList);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const statusDisplay = (status) => {
    switch (status) {
      case "FOR APPROVAL":
        return (
          <div style={{ display: "flex", alignItems: "center", color: "gray" }}>
            <AccessTimeIcon />
            <div style={{ marginLeft: 5 }}>FOR APPROVAL</div>
          </div>
        );
      case "APPROVED":
        return (
          <div
            style={{ display: "flex", alignItems: "center", color: "green" }}
          >
            <Check />
            <div style={{ marginLeft: 5 }}>APPROVED</div>
          </div>
        );
      case "REJECTED":
        return (
          <div style={{ display: "flex", alignItems: "center", color: "red" }}>
            <CloseIcon />
            <div style={{ marginLeft: 5 }}>REJECTED</div>
          </div>
        );
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleEdit = (event) => {
    setAnchorEl(null);
    props.edit(selected);
  };

  const handleDelete = (event) => {
    setAnchorEl(null);
    deleteBiller();
    getBillers();
  };

  async function deleteBiller() {
    let response = await LocalBillersService.deleteBiller(selected.code);
    if (response && response.success) {
      setOpen(true);
    } else {
      setErrors(response);
    }
  }

  useEffect(() => {
    getBillers();
  }, []);

  return (
    <div style={{ padding: "15px 25px" }}>
      <ThemeProvider theme={theme}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <div style={{ fontSize: 32 }}>Local Billers</div>
          <Button
            style={{ width: 150 }}
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => props.enroll(true)}
          >
            Enroll Biller
          </Button>
        </div>
        <Paper
          style={{
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
          }}
          component="form"
        >
          <InputBase
            placeholder="Search Biller"
            style={{ flex: 1, marginLeft: 10 }}
            inputProps={{ "aria-label": "search biller" }}
            onChange={(event) => {
              filteredList && handleFilter(event.target.value);
            }}
          />
          <IconButton type="submit" aria-label="search">
            <Search />
          </IconButton>
        </Paper>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
            component="div"
            count={filteredList && filteredList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Biller Name</TableCell>
                <TableCell align="left">Industry</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="left">Enrollment Date</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredList &&
                (rowsPerPage > 0
                  ? filteredList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredList
                ).map((row, index) => (
                  <TableRow>
                    <TableCell
                      style={{ fontWeight: "bold" }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.industry}</TableCell>
                    <TableCell align="left">{`${row.address}, ${row.barangay}, ${row.city}, ${row.zipCode}`}</TableCell>
                    <TableCell align="left">
                      {moment(row.updatedAt).format("YYYY-MM-DD hh:mm:ss A")}
                    </TableCell>
                    <TableCell align="left">
                      {statusDisplay(row.status)}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        {
                          setSelected(row);
                        }
                      }}
                      align="right"
                    >
                      <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        aria-label="more"
                      >
                        <MoreHoriz />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem
                          style={{ pading: 15 }}
                          key={row}
                          onClick={handleEdit}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Create />
                            <div style={{ marginLeft: 10 }}>
                              Edit Biller Details
                            </div>
                          </div>
                        </MenuItem>
                        <MenuItem
                          style={{ padding: 15 }}
                          onClick={handleDelete}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <MdIcon path={mdiTrashCanOutline} size="24px" />
                            <div style={{ marginLeft: 10 }}>Delete Biller</div>
                          </div>
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Update Success"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Details has been successfully updated.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <UIActions error={errors} />
      </ThemeProvider>
    </div>
  );
};
export default LocalBillerList;
