import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const UIActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpenError] = React.useState(true);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenError(false);
  };
  if (props.error) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else return null;
};

export default UIActions;
