import axios from "axios";

const { REACT_APP_LOCAL_BILLERS_API_URI } = process.env;

const instance = axios.create({
  baseURL: REACT_APP_LOCAL_BILLERS_API_URI,
  timeout: 70000,
});

function createHeaders(customHeaders = {}) {
  let headers = Object.assign({}, customHeaders);
  let userData = localStorage.getItem("@@i2i/USER");
  if (userData) {
    let jsonData = JSON.parse(userData);
    headers["Authorization"] = "Bearer " + jsonData.token;
  }
  return headers;
}

export default {
  instance,

  get: async (path, options = {}) => {
    if (options) {
      options.headers = createHeaders(options.headers);
    }
    try {
      return await instance.get(path, options);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  post: async (path, data, options = {}) => {
    options.headers = createHeaders(options.headers);
    try {
      return await instance.post(path, data, options);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  put: async (path, data, options = {}) => {
    options.headers = createHeaders(options.headers);
    try {
      return await instance.put(path, data, options);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  delete: async (path, options = {}) => {
    options.headers = createHeaders(options.headers);
    try {
      return await instance.delete(path, options);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};
