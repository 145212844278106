import React, { useState, useEffect } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";

import LocalBillersService from "../../services/LocalBillersService";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";

import UIActions from "../../actions/UIActions";
import { ErrorTexts } from "../../enums";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F47721",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#F47721",
    },
    charcoal: {
      main: "#FFFFFF",
      contrastText: "#2B2D33",
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: "none",
      },
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  backgroundColor: "#FFFFFF",
});

const getSteps = () => {
  return ["Biller Details", "Users", "Add Fields", "Review"];
};

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "+",
        "6",
        "3",
        "9",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const getStepContent = (
  step,
  inputsValue,
  onChangeHandler,
  city,
  setCity,
  barangay,
  setBarangay,
  handleValidations,
  billerEmailError,
  userEmailError,
  setErrors
) => {
  async function getCity() {
    let response = await LocalBillersService.getCity();
    if (response === ErrorTexts.services.message) {
      setErrors(response);
    } else {
      setCity(response);
    }
  }

  async function getBarangay() {
    let response = await LocalBillersService.getBarangay(inputsValue.city);
    if (response === ErrorTexts.services.message) {
      setErrors(response);
    } else {
      setBarangay(response);
    }
  }

  switch (step) {
    case 0:
      return (
        <div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <TextField
              name="name"
              label="Biller Name"
              required
              fullWidth
              style={{ marginRight: 20 }}
              value={inputsValue.name}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              name="industry"
              label="Industry"
              onFocus={handleValidations}
              fullWidth
              required
              value={inputsValue.industry}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <TextField
            name="address"
            label="Street Address"
            fullWidth
            required
            style={{ marginBottom: 20 }}
            value={inputsValue.address}
            onChange={onChangeHandler}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div style={{ display: "flex", marginBottom: 20 }}>
            <FormControl required fullWidth style={{ marginRight: 20 }}>
              <InputLabel>Area / Province</InputLabel>
              <Select
                value={inputsValue.city}
                renderValue={(value) => value}
                name="city"
                onOpen={() => getCity()}
                onClick={onChangeHandler}
              >
                {city &&
                  city.map((name, index) => {
                    return <MenuItem value={name}>{name}</MenuItem>;
                  })}
              </Select>
            </FormControl>

            <FormControl required fullWidth style={{ marginRight: 20 }}>
              <InputLabel>City / Barangay</InputLabel>
              <Select
                name="barangay"
                value={inputsValue.barangay}
                renderValue={(value) => value}
                disabled={inputsValue.city ? false : true}
                onClick={onChangeHandler}
                onOpen={() => getBarangay()}
              >
                {barangay &&
                  barangay.map((name, index) => {
                    return <MenuItem value={name}>{name.barangay}</MenuItem>;
                  })}
              </Select>
            </FormControl>
            <TextField
              required
              name="zipCode"
              label="Zipcode"
              inputProps={{ readOnly: true }}
              fullWidth
              value={inputsValue.zipCode}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <TextField
              required
              name="bank"
              label="Bank"
              fullWidth
              style={{ marginRight: 20 }}
              value={inputsValue.bank}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              name="accountNumber"
              label="Bank Account Number"
              fullWidth
              value={inputsValue.accountNumber}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <FormControl fullWidth style={{ marginRight: 20 }}>
              <InputLabel htmlFor="formatted-text-mask-input">
                Contact Number
              </InputLabel>
              <Input
                value={inputsValue.contactNumber}
                onChange={onChangeHandler}
                name="contactNumber"
                inputComponent={TextMaskCustom}
              />
            </FormControl>
            <TextField
              required
              fullWidth
              name="billerEmail"
              label="Email Address"
              error={billerEmailError}
              helperText={billerEmailError ? "Invalid email address" : ""}
              value={inputsValue.billerEmail}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
      );
    case 1:
      return (
        <div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <TextField
              required
              name="lastName"
              label="Last Name"
              fullWidth
              style={{ marginRight: 20 }}
              value={inputsValue.lastName}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              name="firstName"
              label="First Name"
              fullWidth
              style={{ marginRight: 20 }}
              value={inputsValue.firstName}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              name="middleName"
              label="Middle Name"
              fullWidth
              value={inputsValue.middleName}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <TextField
              required
              name="position"
              label="Position"
              fullWidth
              style={{ marginRight: 20 }}
              value={inputsValue.position}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControl fullWidth style={{ marginRight: 20 }}>
              <InputLabel htmlFor="formatted-text-mask-input">
                Mobile Number
              </InputLabel>
              <Input
                value={inputsValue.mobileNumber}
                onChange={onChangeHandler}
                name="mobileNumber"
                inputComponent={TextMaskCustom}
              />
            </FormControl>
            <TextField
              required
              error={userEmailError}
              helperText={userEmailError ? "Invalid email address" : ""}
              name="userEmail"
              label="Email Address"
              fullWidth
              value={inputsValue.userEmail}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
      );
    case 2:
      return (
        <div>
          <Button
            style={{ textDecoration: "underline" }}
            startIcon={<AddCircleOutline />}
            color="primary"
          >
            Add New Field
          </Button>
        </div>
      );
    case 3:
      return (
        <div>
          <div style={{ fontSize: 20, marginBottom: 30 }}>Biller Details</div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <TextField
              required
              name="name"
              label="Biller Name"
              fullWidth
              style={{ marginRight: 20 }}
              value={inputsValue.name}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              name="industry"
              label="Industry"
              fullWidth
              value={inputsValue.industry}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <TextField
            required
            name="address"
            label="Street Address"
            fullWidth
            style={{ marginBottom: 20 }}
            value={inputsValue.address}
            onChange={onChangeHandler}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div style={{ display: "flex", marginBottom: 20 }}>
            <TextField
              required
              name="city"
              label="Area / Province"
              fullWidth
              propH
              style={{ marginRight: 20 }}
              value={inputsValue.city}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              name="barangay"
              label="City / Barangay"
              fullWidth
              value={inputsValue.barangay}
              style={{ marginRight: 20 }}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              name="zipCode"
              label="Zipcode"
              inputProps={{ readOnly: true }}
              fullWidth
              value={inputsValue.zipCode}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <TextField
              required
              name="bank"
              label="Bank"
              fullWidth
              style={{ marginRight: 20 }}
              value={inputsValue.bank}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              name="accountNumber"
              label="Bank Account Number"
              fullWidth
              value={inputsValue.accountNumber}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <FormControl fullWidth style={{ marginRight: 20 }}>
              <InputLabel htmlFor="formatted-text-mask-input">
                Contact Number
              </InputLabel>
              <Input
                value={inputsValue.contactNumber}
                onChange={onChangeHandler}
                name="contactNumber"
                inputComponent={TextMaskCustom}
              />
            </FormControl>
            <TextField
              required
              error={billerEmailError}
              helperText={billerEmailError ? "Invalid email address" : ""}
              name="billerEmail"
              label="Email Address"
              fullWidth
              value={inputsValue.billerEmail}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ fontSize: 20, marginBottom: 30 }}>Biller Users</div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <TextField
              required
              name="lastName"
              label="Last Name"
              fullWidth
              style={{ marginRight: 20 }}
              value={inputsValue.lastName}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              name="firstName"
              label="First Name"
              fullWidth
              style={{ marginRight: 20 }}
              value={inputsValue.firstName}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              required
              name="middleName"
              label="Middle Name"
              fullWidth
              value={inputsValue.middleName}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <TextField
              required
              name="position"
              label="Position"
              fullWidth
              style={{ marginRight: 20 }}
              value={inputsValue.position}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControl fullWidth style={{ marginRight: 20 }}>
              <InputLabel htmlFor="formatted-text-mask-input">
                Mobile Number
              </InputLabel>
              <Input
                value={inputsValue.mobileNumber}
                onChange={onChangeHandler}
                name="mobileNumber"
                inputComponent={TextMaskCustom}
              />
            </FormControl>
            <TextField
              required
              error={userEmailError}
              helperText={userEmailError ? "Invalid email address" : ""}
              name="userEmail"
              label="Email Address"
              fullWidth
              value={inputsValue.userEmail}
              onChange={onChangeHandler}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
      );
    default:
      return "invalid";
  }
};

const EditBiller = (props) => {
  const [inputsValue, setInputsValue] = useState({
    name: props.initialValue.name,
    industry: props.initialValue.industry,
    address: props.initialValue.address,
    city: props.initialValue.city,
    barangay: props.initialValue.barangay,

    zipCode: props.initialValue.zipCode,
    bank: props.initialValue.bank,
    accountNumber: props.initialValue.accountNumber,
    contactNumber: props.initialValue.contactNumber,
    billerEmail: props.initialValue.email,

    lastName: props.initialValue.users[0].lastName,
    firstName: props.initialValue.users[0].firstName,
    middleName: props.initialValue.users[0].middleName,
    position: props.initialValue.users[0].position,
    mobileNumber: props.initialValue.users[0].mobileNumber,
    userEmail: props.initialValue.users[0].email,
  });

  const [city, setCity] = useState();
  const [barangay, getBarangay] = useState();
  const [billerEmailError, setBillerEmailError] = React.useState(null);
  const [userEmailError, setuserEmailError] = React.useState(null);

  const [valid, setValid] = React.useState(true);

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState("");

  const onChangeHandler = (event) => {
    handleValidations(event);
    if (event.target.name === "barangay") {
      setInputsValue({
        ...inputsValue,
        [event.target.name]: event.target.value.barangay,
        zipCode: event.target.value.zipCode,
      });
    } else {
      setInputsValue({
        ...inputsValue,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleValidations = (event) => {
    if (
      inputsValue.billerEmail &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        inputsValue.billerEmail
      )
    ) {
      setBillerEmailError(true);
    } else {
      setBillerEmailError(false);
    }

    if (
      inputsValue.userEmail &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        inputsValue.userEmail
      )
    ) {
      setuserEmailError(true);
    } else {
      setuserEmailError(false);
    }

    if (
      //Biller Details
      inputsValue.name &&
      inputsValue.industry &&
      inputsValue.address &&
      inputsValue.city &&
      inputsValue.barangay &&
      inputsValue.zipCode &&
      inputsValue.bank &&
      inputsValue.accountNumber !== "+639         " &&
      inputsValue.contactNumber.trim() !== "+639" &&
      inputsValue.contactNumber.trim().length === 13 &&
      inputsValue.billerEmail &&
      !billerEmailError &&
      activeStep === 0
    ) {
      setValid(false);
    } else if (
      //Users
      inputsValue.lastName &&
      inputsValue.firstName &&
      inputsValue.middleName &&
      inputsValue.position &&
      inputsValue.mobileNumber.trim() !== "+639" &&
      inputsValue.mobileNumber.trim().length === 13 &&
      inputsValue.userEmail &&
      !userEmailError &&
      activeStep === 1
    ) {
      setValid(false);
    } else if (
      //Add Fields
      activeStep === 2
    ) {
      setValid(false);
    } else if (
      //Review
      inputsValue.name &&
      inputsValue.industry &&
      inputsValue.address &&
      inputsValue.city &&
      inputsValue.barangay &&
      inputsValue.zipCode &&
      inputsValue.bank &&
      inputsValue.accountNumber &&
      inputsValue.contactNumber.trim() !== "+639" &&
      inputsValue.contactNumber.trim().length === 13 &&
      inputsValue.billerEmail &&
      inputsValue.lastName &&
      inputsValue.firstName &&
      inputsValue.middleName &&
      inputsValue.position &&
      inputsValue.mobileNumber.trim() !== "+639" &&
      inputsValue.mobileNumber.trim().length === 13 &&
      inputsValue.userEmail &&
      !billerEmailError &&
      !userEmailError &&
      activeStep === 3
    ) {
      setValid(false);
    } else {
      setValid(true);
    }
  };
  useEffect(() => {
    handleValidations();
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setOpen(false);
    props.edit(false);
  };

  const handleUpdate = () => {
    let requestObject = {
      code: props.initialValue.code,
      biller: {
        name: inputsValue.name,
        industry: inputsValue.industry,
        address: inputsValue.address,
        bank: inputsValue.bank,
        city: inputsValue.city,
        barangay: inputsValue.barangay,
        zipCode: inputsValue.zipCode,
        accountNumber: inputsValue.accountNumber,
        contactNumber: inputsValue.contactNumber,
        email: inputsValue.billerEmail,
      },
      user: [
        {
          firstName: inputsValue.firstName,
          middleName: inputsValue.middleName,
          lastName: inputsValue.lastName,
          position: inputsValue.position,
          mobileNumber: inputsValue.mobileNumber,
          email: inputsValue.userEmail,
        },
      ],
      references: [{}],
    };
    updateBiller(requestObject);
  };

  async function updateBiller(requestObject) {
    let response = await LocalBillersService.updateBiller(requestObject);

    if (response && response.success) {
      setOpen(true);
    } else {
      setErrors(response);
    }
  }

  return (
    <div style={{ padding: "15px 25px" }}>
      <ThemeProvider theme={theme}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 32, marginBottom: 15 }}>Edit Biller</div>
        </div>
        <Paper>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div style={{ padding: 40 }}>
            <div>
              {getStepContent(
                activeStep,
                inputsValue,
                onChangeHandler,
                city,
                setCity,
                barangay,
                getBarangay,
                handleValidations,
                billerEmailError,
                userEmailError,
                setErrors
              )}
            </div>
            <div
              style={{
                marginTop: 100,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={
                  activeStep === 0 ? () => props.edit(false) : handleBack
                }
              >
                {activeStep === 0 ? "Cancel" : "Back"}
              </Button>
              <Button
                endIcon={<KeyboardArrowRight />}
                variant="contained"
                color="primary"
                disabled={valid}
                onClick={activeStep === 3 ? handleUpdate : handleNext}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </div>
        </Paper>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Update Success"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Details has been successfully updated.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <UIActions error={errors} />
      </ThemeProvider>
    </div>
  );
};

export default EditBiller;
