export default {
  search(value, list) {
    let searchList = [];
    value
      ? list.forEach((item) => {
          let itemString = JSON.stringify(
            item.name ? item.name : item
          ).toLowerCase();
          if (itemString.includes(value.toLowerCase())) {
            searchList.push(item);
          }
        })
      : (searchList = null);
    return searchList;
  },
};
