const ErrorTexts = {
	services: {
		title: "Error",
		message: "A connection error has occured. Please check your internet connection and try again."
	},
	actions: {
		title: "Error",
		message: "Something bad has happened. Please try again."
	},
	test: {
		title: "Test Error",
		message: "This is a test error response message"
	}
}

export default ErrorTexts;